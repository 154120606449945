import { PermissionType, RoleType } from 'api/types/entity';
import { NavigationLink } from 'components/NavigationLink';
import { TranslationPath, useTranslation } from 'libs/i18n';
import { ROUTES } from 'libs/navigation';
import { usePermissions } from 'libs/permissions';
import { useUser } from 'libs/providers';
import { Typography } from 'libs/ui';

import { ContainerStyled } from './NavigationMenu.styles';

interface MenuType {
  title: TranslationPath;
  path: string;
  permissions?: PermissionType[];
  role?: RoleType;
}

const menu: MenuType[] = [
  {
    title: 'menu.clients',
    path: ROUTES.clients.fullPath,
  },
  {
    title: 'menu.admins',
    path: ROUTES.admins.fullPath,
    permissions: [PermissionType.AdminRead],
  },
  {
    title: 'menu.systemSettings',
    path: ROUTES.systemSettings.fullPath,
    permissions: [PermissionType.SystemSettingGetOne],
    role: RoleType.SuperAdmin,
  },
];

export const NavigationMenu = () => {
  const { t } = useTranslation();

  const { checkPermissions, checkRole } = usePermissions();

  const { user } = useUser();

  if (!user) {
    return null;
  }

  const filteredMenu = menu.filter((m) => {
    const permissions = m.permissions
      ? checkPermissions(m.permissions, 'or')
      : true;
    const role = m.role ? checkRole(m.role) : true;

    return permissions && role;
  });

  return (
    <ContainerStyled alignItems="center" direction="row" spacing={48}>
      {filteredMenu.map((v) => (
        <NavigationLink key={v.path} to={v.path}>
          <Typography className="navigationMenu-link" variant="bodyMedium">
            {t(v.title)}
          </Typography>
        </NavigationLink>
      ))}
    </ContainerStyled>
  );
};
