import { OccupationType } from 'api/types/entity';
import { UpdateClientParams } from 'api/types/params';
import { yesOrNot } from 'helpers/yesOrNo';
import {
  parseAdditionalInformationFileIdsParam,
  parseAddressParam,
  parseTaxResidencyParam,
  parsePepConnectionParam,
  parseBiographyParam,
  parseCitizenshipsParam,
  parseServiceContractParam,
  parseAdditionalDocumentFileIdsParam,
} from 'modules/client/common/helpers';
import { parseIdentityDocumentParam } from 'modules/client/common/helpers/parseParams/parseIdentityDocumentParam';

import { ClientFormType } from '../../forms/clientForm';

import { getBankAccountsParam } from './getBankAccountsParam';
import { getDebtsParam } from './getDebtsParam';
import { getFinancingSourcesParam } from './getFinancingSourcesParam';
import { getWithdrawalAccountsParam } from './getWithdrawalAccountsParam';

export const getClientSubmitData = (
  versionNumber: number,
  values: ClientFormType
): UpdateClientParams => ({
  versionNumber,
  reason: values.reasonChange.reason,
  firstName: values.personalData.firstName,
  lastName: values.personalData.lastName,
  middleName: values.personalData.middleName ?? null,
  dateOfBirth: values.personalData.dateOfBirth,
  bankAccounts: getBankAccountsParam(values),
  biography: parseBiographyParam(values),
  citizenships: parseCitizenshipsParam(values),
  debts: getDebtsParam(
    values,
    values.biography?.occupation?.value === OccupationType.entrepreneur
  ),
  financingSources: getFinancingSourcesParam(values),

  orderSubmissionContacts: values.orderSubmissionContacts.map(
    ({ type, value }) => ({
      value,
      type: type.value,
    })
  ),

  ...parseAddressParam(values),

  taxResidences: parseTaxResidencyParam(values),

  withdrawalAccounts: getWithdrawalAccountsParam(values),
  pepConnection: values.pepConnection.relation?.value!,
  connectedPepInfo: parsePepConnectionParam(values),
  accreditedInvestorStatus:
    yesOrNot(values.statuses.accreditedInvestorStatus?.value) ?? null,

  riskLevel: values.riskLevel.riskLevel?.value ?? null,
  informationUpdateFrequency:
    values.riskLevel.informationUpdateFrequency?.value ?? null,
  riskLevelReviewDate: values.riskLevel.riskLevelReviewDate ?? null,
  lastInformationUpdateDate: values.riskLevel.lastInformationUpdateDate ?? null,
  lastOperationMonitoringResults:
    values.riskLevel.lastOperationMonitoringResults ?? null,
  usedServices: values.riskLevel.usedServices ?? null,

  code: values.accountCodes.code ?? null,
  centralDepositoryAccountNumber:
    values.accountCodes.centralDepositoryAccountNumber ?? null,
  centralDepositoryAccountNumberOpeningDate:
    values.accountCodes.centralDepositoryAccountNumberOpeningDate ?? null,
  personalAccountNumber: values.accountCodes.personalAccountNumber,
  personalAccountNumberOpeningDate:
    values.accountCodes.personalAccountNumberOpeningDate,
  managerId: values.accountCodes.manager?.id ?? null,

  additionalInformationFileIds: parseAdditionalInformationFileIdsParam(
    values.additionalDocumentsFiles
  ),

  additionalDocumentFileIds: parseAdditionalDocumentFileIdsParam(
    values.documentsFiles
  ),

  openAccountOrder: {
    date: values.openAccountOrder.date || null,
    number: values.openAccountOrder.number || null,
    orderReceivedDate: values.openAccountOrder.orderReceivedDate || null,
  },

  category: values.general.category?.value || null,
  economicSector: values.general.economicSector?.value || null,
  taxBenefits: values.general.taxBenefits || null,
  managementType: values.general.managementType?.value || null,
  isFinancialMonitoringSubject:
    yesOrNot(values.general.isFinancialMonitoringSubject?.value) ?? null,
  isKazakhstanResident:
    yesOrNot(values.general.isKazakhstanResident?.value) ?? null,

  serviceContract: parseServiceContractParam(values),

  identityDocument: parseIdentityDocumentParam(values),

  tariff: values.general.tariff?.value,
});
