import { LegalClientBranchEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';
import { CompanyBaseStateRegistrationFormType } from 'modules/client/common/forms/companyStateRegistrationForm';
import { parseAddressValue } from 'modules/client/common/helpers';

import { LegalClientBranchFormType } from '../../forms/legalClientBranchForm';

export const getLegalClientBranchInitialValue = (
  client: LegalClientBranchEntity,
  t: TFunc
): LegalClientBranchFormType => ({
  contacts: {
    email: client.contacts?.email ?? '',
    phone: client.contacts?.phoneNumber ?? '',
  },

  reasonChange: { reason: '' },

  companyTaxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    taxNumber: v.taxId,
  })) || [{ country: null, taxNumber: '' }],

  companyRegistrationAddress: parseAddressValue(client.legalAddress),
  companyResidentialAddress: parseAddressValue(client.actualAddress),

  companyLicense: {
    companyActivityIsLicensed: yesOrNotOption(t, client.isLicensed),
    authorityName: client.licenseInfo?.issuingAuthority ?? '',
    dateOfExpiry: client.licenseInfo?.expirationDate,
    dateOfIssue: client.licenseInfo?.issueDate,
    licensedActivityType: client.licenseInfo?.activityType ?? '',
    licenseNumber: client.licenseInfo?.licenseNumber ?? '',
  },

  companyName: { fullName: client.fullName ?? '' },

  companyStateRegistration: client.stateRegistration
    ? {
        date: client.stateRegistration.initialStateRegistrationDate,
        dateOfIssue:
          client.stateRegistration.stateRegistrationConfirmationDocument
            .dateOfIssue,
        nameAuthority: client.stateRegistration.registrationAuthorityName,
        number: client.stateRegistration.registrationNumber,
        registrationDocumentName:
          client.stateRegistration.registrationDocumentName,
      }
    : ({} as CompanyBaseStateRegistrationFormType['companyStateRegistration']),
  additionalDocumentsFiles: client.additionalInformationFiles,
});
