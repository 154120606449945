// eslint-disable-next-line no-restricted-imports
import {
  ManagerFragment,
  Query_ClientController_GetById_Onboarding_Statuses_Items_Value as OnboardingStatusValue,
  Query_ClientController_GetList_AllOf_1_List_Items_Onboarding_Statuses_Items_Value as OnboardingStatusValue2,
} from 'api/generated/graphql';
import { ClientOnboardingData } from 'api/types/data';
import { lodash } from 'helpers';
import { Maybe } from 'types/maybe';

import { parseManagerData } from '../parseManagerData';
import { OnboardingDataApi, parseOboardingData } from '../parseOboardingData';

import { parseClientOnboardingStatus } from './parseEntity/parseClientOnboardingStatus';

type ClientOnboardingDataStatusApi = {
  value: OnboardingStatusValue | OnboardingStatusValue2;
  resultSetBy?: ManagerFragment | null;
  resultSetAt?: string;
  createdAt: string;
  result?: string | null;
};

export type ClientOnboardingDataApi =
  | (OnboardingDataApi & {
      statuses?: Maybe<ClientOnboardingDataStatusApi>[] | null;
    })
  | undefined
  | null;

interface Client {
  onboarding?: ClientOnboardingDataApi | null;
  representative?: { onboarding?: OnboardingDataApi | null } | null;
}

export const parseClientOnboardingData = (
  client?: Client
): ClientOnboardingData | undefined => {
  const onboardingData: OnboardingDataApi = {
    ...client?.onboarding,
    totalRoutes:
      client?.representative?.onboarding?.totalRoutes ??
      client?.onboarding?.totalRoutes ??
      0,
    traversedRoutes:
      client?.representative?.onboarding?.traversedRoutes ??
      client?.onboarding?.traversedRoutes ??
      [],
  };
  const baseOnboardingData = parseOboardingData(onboardingData);

  if (!baseOnboardingData) {
    return undefined;
  }

  const statuses = client?.onboarding?.statuses
    ? lodash
        .compact(client.onboarding.statuses)
        .map(({ resultSetAt, resultSetBy, createdAt, value, result }) => ({
          value: parseClientOnboardingStatus(value),
          createdBy: resultSetBy ? parseManagerData(resultSetBy) : null,
          createdAt: new Date(resultSetAt || createdAt),
          result: result ?? '',
        }))
    : undefined;

  return {
    ...baseOnboardingData,
    statuses,
  };
};
