import { BaseRepresentativeClientEntity } from 'api/types/entity';
import { RegistrationAddressEntity } from 'api/types/entity/client/registrationAddressEntity';
import { TFunc } from 'libs/i18n';
import {
  parseBiographyInitialValue,
  parsePepConnectionInitialValue,
  parseBaseDebtsInitialValue,
  parseIdentityDocumentInitialValue,
} from 'modules/client/common/helpers';
import { Maybe } from 'types/maybe';

import { AddressFormType } from '../../forms/registrationAddressForm';
import { RepresentativeFormType } from '../../forms/representativeForm';

import { parseDocumentsFiles } from './parseDocumentsFiles';
import { parsePowerOfAttorney } from './parsePowerOfAttorney';

const parseAddress = (
  address?: Maybe<RegistrationAddressEntity>
): AddressFormType => ({
  country: address?.country,
  locality: address?.locality ?? '',
  region: address?.region ?? '',
  district: address?.district || undefined,
  street: address?.street ?? '',
  house: address?.house ?? '',
  building: address?.building || undefined,
  apartment: address?.apartment || undefined,
  postalCode: address?.postalCode ?? '',
});

export const getBaseRepresentativeInitialValue = (
  client: BaseRepresentativeClientEntity,
  t: TFunc
): RepresentativeFormType => ({
  reasonChange: { reason: '' },
  contacts: {
    email: client.email,
    phone: client.phone,
  },

  personalData: {
    firstName: client.firstName,
    lastName: client.lastName,
    middleName: client.middleName ?? '',
    dateOfBirth: client.dateOfBirth,
  },

  citizenships: client.citizenships ?? [null],

  taxResidences: client.taxResidences?.map((v) => ({
    country: v.country || null,
    reasonForAbsence: v.reasonForMissingTaxId ?? '',
    taxNumber: v.taxId ?? '',
    taxNumberIsMissing: !v.taxId && !!v.reasonForMissingTaxId,
  })) || [{ country: null }],

  registrationAddress: parseAddress(client.registrationAddress),
  residentialAddress: parseAddress(client.residentialAddress),

  placeOfBirth: {
    country: client.placeOfBirth?.country ?? null,
    locality: client.placeOfBirth?.locality ?? '',
  },

  biography: parseBiographyInitialValue(t, client.biography),

  bankAccounts: client.bankAccounts?.map((v) => ({
    country: v.country || null,
    swiftCode: v.swiftCode,
  })) || [{ country: null, swiftCode: '' }],

  pepConnection: parsePepConnectionInitialValue(client.pepConnection, t),

  debts: parseBaseDebtsInitialValue(client.debts, t),

  activity: {},

  powerOfAttorney: parsePowerOfAttorney(client.powerOfAttorney),
  additionalDocumentsFiles: client.additionalInformationFiles,

  documentsFiles: parseDocumentsFiles(client),
  identityDocument: parseIdentityDocumentInitialValue(
    t,
    client.identityDocument
  ),
});
