import { FC } from 'react';

import {
  ManagerData,
  ClientOnboardingData,
  OnboardingDataStatus,
} from 'api/types/data';
import { CountryEntity, KycEntity, OccupationType } from 'api/types/entity';
import { Stack } from 'libs/ui';
import { OrderSubmissionSection } from 'modules/client/common/components';
import {
  DebtsSection,
  WithdrawalAccountsSection,
  PersonalDataSection,
  CitizenshipSection,
  PlaceOfBirthSection,
  BiographySection,
  ContactsSection,
  PepConnectionSection,
  TaxResidencySection,
  BankAccountsSection,
  ActivitySection,
  AdditionalDocumentsSection,
  DocumentsSection,
  AccountCodesSection,
  IdentityDocumentSection,
  OpenAccountOrderSection,
  ServiceContractSection,
  RiskLevelSection,
  ResultCheckSection,
} from 'modules/client/common/components/sections';

import { useFormContext } from '../../forms/clientForm';
import {
  FinancingSourcesSection,
  GeneralSection,
  RegistrationAddressSection,
  ResidentialAddressSection,
} from '../sections';

export interface ClientSectionsProps {
  countriesLoading?: boolean;
  countries: CountryEntity[];
  kycData?: KycEntity;
  onboardingData?: ClientOnboardingData;
  managers: ManagerData[];
  managersLoading?: boolean;
  visibleOnboardingStatuses?: OnboardingDataStatus[];
  hideActivitySection?: boolean;
  isEditablePersonalAccountNumber?: boolean;
}

export const ClientSections: FC<ClientSectionsProps> = ({
  countries,
  countriesLoading,
  kycData,
  onboardingData,
  managers,
  managersLoading,
  visibleOnboardingStatuses,
  hideActivitySection,
  isEditablePersonalAccountNumber,
}) => {
  const { values } = useFormContext();

  const isEntrepreneur =
    values.biography?.occupation?.value === OccupationType.entrepreneur;

  return (
    <Stack spacing={72}>
      <ResultCheckSection
        kycData={kycData}
        statuses={visibleOnboardingStatuses}
      />
      <GeneralSection onboardingData={onboardingData} />
      <OpenAccountOrderSection />
      <ServiceContractSection />
      <RiskLevelSection />
      {!hideActivitySection && <ActivitySection />}
      <AccountCodesSection
        isEditablePersonalAccountNumber={isEditablePersonalAccountNumber}
        managers={managers}
        managersLoading={managersLoading}
      />
      <ContactsSection />
      <PersonalDataSection />
      <CitizenshipSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <TaxResidencySection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <IdentityDocumentSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <PlaceOfBirthSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <RegistrationAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <ResidentialAddressSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <BiographySection />

      <BankAccountsSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <FinancingSourcesSection />
      <PepConnectionSection />
      <DebtsSection isEntrepreneur={isEntrepreneur} />
      <WithdrawalAccountsSection
        countries={countries}
        countriesLoading={countriesLoading}
      />

      <OrderSubmissionSection />

      <DocumentsSection />
      <AdditionalDocumentsSection />
    </Stack>
  );
};
