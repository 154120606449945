import { LegalClientLicenseInfoEntity } from 'api/types/entity';
import { yesOrNotOption } from 'helpers';
import { TFunc } from 'libs/i18n';

import { CompanyLicenseFormType } from '../../forms/companyLicenseForm';

interface Props {
  isLicensed?: boolean;
  licenseInfo?: LegalClientLicenseInfoEntity;
}

export const parseCompanyLicense = (
  t: TFunc,
  client: Props
): CompanyLicenseFormType['companyLicense'] => ({
  companyActivityIsLicensed: yesOrNotOption(t, client.isLicensed),
  authorityName: client.licenseInfo?.issuingAuthority ?? '',
  dateOfExpiry: client.licenseInfo?.expirationDate,
  dateOfIssue: client.licenseInfo?.issueDate,
  licensedActivityType: client.licenseInfo?.activityType ?? '',
  licenseNumber: client.licenseInfo?.licenseNumber ?? '',
  files: client.licenseInfo?.files,
});
